<template>
  <component :is="ref ? 'router-link' : 'span'" v-bind="bind" :class="{'no-owner': !title}">
    {{ title || '(no owner)' }}
  </component>
</template>

<script>
export default {
  name: 'OwnerFieldReadonly',
  props: {
    value: {
      type: Object,
      default: null
    }
  },
  computed: {
    title() {
      return this.value && this.value.title;
    },
    ref() {
      return this.value && this.value.ref;
    },
    link() {
      if (this.ref) {
        return {name: 'person', params: {id: this.ref.id}};
      }
      return null;
    },
    bind() {
      const bindings = {};
      if (this.link) {
        bindings.to = this.link;
      }
      return bindings;
    }
  }
};
</script>

<style scoped>
.no-owner {
  color: var(--v-grey-lighten3);
}
</style>
