<template>
  <div class="period-input">
    <v-menu
        offset-y
        nudge-top="-1"
        v-model="startDateChooserVisible"
        :close-on-content-click="false"
        content-class="rounded-t-0">
      <template #activator="{on, attrs}">
        <v-text-field v-on="on" v-bind="attrs" hide-details readonly :value="formattedStartDate" class="ma-0 pa-0"/>
      </template>
      <v-date-picker
          v-model="startYearMonthDay"
          no-title
          @input="startDateChooserVisible = false"
          class="rounded-t-0"
          :first-day-of-week="localeFirstDayOfWeek"/>
    </v-menu>

    <v-combobox
        class="ma-0 pa-0"
        :items="availableStartTimes"
        no-filter
        v-model="startTime"
        ref="startTime"
        :value-comparator="compareValues"
        hide-details
        :menu-props="{minWidth: 150}"/>
    <v-menu
        offset-y
        nudge-top="-1"
        v-model="endDateChooserVisible"
        :close-on-content-click="false"
        content-class="rounded-t-0">
      <template #activator="{on, attrs}">
        <v-text-field v-on="on" v-bind="attrs" hide-details readonly :value="formattedEndDate" class="ma-0 pa-0"/>
      </template>
      <v-date-picker
          v-model="endYearMonthDay"
          no-title
          @input="endDateChooserVisible = false"
          class="rounded-t-0"
          :first-day-of-week="localeFirstDayOfWeek"/>
    </v-menu>
    <v-combobox
        class="ma-0 pa-0"
        :items="availableEndTimes"
        no-filter
        v-model="endTime"
        ref="endTime"
        :value-comparator="compareValues"
        hide-details
        :menu-props="{minWidth: 150}">
      <template #item="{item, on, attrs}">
        <v-list-item style="min-width: 10em" v-on="on" v-bind="attrs">
          <v-list-item-title>{{ item.text }}</v-list-item-title>
          <v-list-item-action-text class="text-no-wrap pr-2">({{ item.dur }})</v-list-item-action-text>
        </v-list-item>
      </template>
    </v-combobox>
  </div>
</template>

<script>
import {
  addTime,
  currentDate, fromYearMonthDay,
  hours,
  minutes,
  startOfDay,
  toDate,
  toYearMonthDay
} from '@/util/dates';
import {isAnInvalidTime, timeOfDay} from '@/util/times';
import {mapGetters} from 'vuex';

export default {
  name: 'PeriodInput',
  props: {
    start: {
      type: Number,
      default: null
    },
    end: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      localStart: null,
      localEnd: null,
      startDateChooserVisible: false,
      endDateChooserVisible: false,
      timeResolution: 30 * minutes // directly affects how many items show in the time combo boxes
    };
  },
  computed: {
    ...mapGetters('sites/active', ['localeFirstDayOfWeek']),
    startYearMonthDay: {
      get() {
        return toYearMonthDay(this.parsedStartDate);
      },
      set(v) {
        const diff = this.parsedEndDate - this.parsedStartDate;
        const oldStart = this.parsedStartDate;
        const newStart = fromYearMonthDay(v);
        newStart.setHours(oldStart.getHours(), oldStart.getMinutes(), oldStart.getSeconds());
        this.localStart = newStart.getTime();
        this.localEnd = newStart.getTime() + diff;
      }
    },
    endYearMonthDay: {
      get() {
        return toYearMonthDay(this.parsedEndDate);
      },
      set(v) {
        const oldEnd = this.parsedEndDate;
        const newEnd = fromYearMonthDay(v);
        newEnd.setHours(oldEnd.getHours(), oldEnd.getMinutes(), oldEnd.getSeconds());
        this.localEnd = newEnd.getTime();
      }
    },
    startTime: {
      get() {
        return this.startTimeItem(this.parsedStartDate);
      },
      set(v) {
        const duration = this.parsedEndDate - this.parsedStartDate;
        this.setTime(v, 'start');
        this.localEnd = this.localStart + duration;
      }
    },
    endTime: {
      get() {
        return this.endTimeItem(this.parsedEndDate, this.parsedStartDate.getTime());
      },
      set(v) {
        this.setTime(v, 'end');
      }
    },
    parsedStartDate() {
      return toDate(this.localStart, this.start, currentDate());
    },
    parsedEndDate() {
      return toDate(this.localEnd, this.end, addTime(this.parsedStartDate, 30 * minutes));
    },
    showSecondDay() {
      return this.parsedStartDate.getDay() !== this.parsedEndDate.getDay();
    },
    formattedStartDate() {
      return this.parsedStartDate.toLocaleDateString(undefined, {
        weekday: 'short',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      });
    },
    formattedEndDate() {
      return this.parsedEndDate.toLocaleDateString(undefined, {
        weekday: 'short',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      });
    },

    availableStartTimes() {
      // 00:00 -> 24:00
      const res = [];
      let date = startOfDay(this.parsedStartDate);
      const day = date.getDay();
      while (date.getDay() === day) {
        res.push(this.startTimeItem(date));
        date = addTime(date, this.timeResolution);
      }
      return res;
    },
    availableEndTimes() {
      const res = [];
      let startDate = this.parsedStartDate;
      let endDate = this.parsedEndDate;
      const start = startDate.getTime();
      const startDay = startDate.getDay();
      const endDay = endDate.getDay();

      if (startDay === endDay) {
        // start time -> 24:00
        while (startDate.getDay() === startDay) {
          res.push(this.endTimeItem(startDate, start));
          startDate = addTime(startDate, this.timeResolution);
        }
      } else {
        // end time -> 24:00
        while (endDate.getDay() === endDay) {
          res.push(this.endTimeItem(endDate, start));
          endDate = addTime(endDate, this.timeResolution);
        }
      }
      return res;
    }
  },
  watch: {
    start(v) {
      this.localStart = null;
    },
    end(v) {
      this.localEnd = null;
    },
    parsedStartDate(v) {
      this.$emit('update:start', v.getTime());
    },
    parsedEndDate(v) {
      this.$emit('update:end', v.getTime());
    }
  },
  methods: {
    setTime(v, bound) {
      if (typeof v === 'string') {
        if (isAnInvalidTime(v)) {
          this.$notify.showError('Not a valid time');
          return;
        }
        if (bound === 'end') {
          const newTime = timeOfDay(this.parsedEndDate, v);
          if (newTime <= this.parsedStartDate) {
            this.$notify.showError('End time before start');
          } else {
            this.localEnd = newTime;
          }
          this.$refs.endTime.isMenuActive = false;
        } else {
          this.localStart = timeOfDay(this.parsedStartDate, v);
          this.$refs.startTime.isMenuActive = false;
        }
      } else {
        if (bound === 'end') {
          this.localEnd = v.value || v;
        } else {
          this.localStart = v.value || v;
        }
      }
    },
    toDay(date) {
      const clone = new Date(date.getTime());
      clone.setHours(0, 0, 0, 0);
      return clone;
    },
    formatTime(d) {
      return d.toLocaleTimeString(undefined, {hour: '2-digit', minute: '2-digit'});
    },
    formatDuration(d, startTime) {
      const diff = d.getTime() - startTime;
      let diffText = '';
      if (diff < minutes) {
        diffText = '0 mins';
      } else if (diff < hours) {
        const asMins = (diff / minutes).toFixed(0);
        if (asMins === '1') {
          diffText = '1 min';
        } else {
          diffText = `${asMins} mins`;
        }
      } else {
        let asHours = (diff / hours).toFixed(1);
        if (asHours.endsWith('.0')) {
          asHours = asHours.substr(0, asHours.length - 2);
        }
        if (asHours === '1') {
          diffText = '1 hour';
        } else {
          diffText = `${asHours} hours`;
        }
      }
      return diffText;
    },
    formatEndTime(d, startTime) {
      return `${this.formatTime(d)} (${this.formatDuration(d, startTime)})`;
    },
    startTimeItem(date) {
      return {text: this.formatTime(date), value: date.getTime()};
    },
    endTimeItem(date, start) {
      return {
        text: this.formatTime(date),
        value: date.getTime(),
        dur: this.formatDuration(date, start)
      };
    },
    compareValues(v1, v2) {
      if (v1 === v2) return true;
      if (!v1 || !v2) return false;
      if (v1.value && v2.value) return v1.value === v2.value;
      let t1 = v1.text;
      let t2 = v2.text;
      if (typeof v1 === 'string') t1 = v1;
      if (typeof v2 === 'string') t2 = v2;
      return t1 === t2;
    }
  }
};
</script>

<style scoped>
.period-input {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 11em 7em;
  grid-template-rows: 2em 2em;
}
</style>
