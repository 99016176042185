<template>
  <div class="grid">
    <v-textarea
        @input="debounceCateringInput"
        :value="internalCateringDescription"
        placeholder="e.g. Tea and coffee for 3 people"
        hide-details
        filled
        class="notes-field"
        auto-grow
        rows="4"
        :readonly="readOnly || !cateringCanBeEdited || !cateringIsEnabled"
        background-color="grey lighten-4"/>
    <v-tooltip bottom v-if="cateringCanBeEdited && cateringIsEnabled">
      <template #activator="{on}">
        <v-btn icon v-on="on" @click="internalCateringDescription = null">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
      Remove this catering request
    </v-tooltip>
  </div>
</template>
<script>
import {debounce} from 'lodash';

export default {
  name: 'CateringDescription',
  props: {
    cateringRequest: {
      type: Object,
      default: null
    },
    cateringIsEnabled: {
      type: Boolean,
      default: false
    },
    cateringCanBeEdited: {
      type: Boolean,
      default: true
    },
    readOnly: Boolean
  },
  computed: {
    internalCateringDescription: {
      get() {
        return this.cateringRequest && this.cateringRequest.description || '';
      },
      set(v) {
        const value = v ? {property: 'description', value: v} : null;
        const ref = this.cateringRequest && this.cateringRequest.ref;
        this.$emit('update:catering-request', {ref, value});
      }
    }
  },
  methods: {
    debounceCateringInput: debounce(function(event) {
      // eslint-disable-next-line no-invalid-this
      this.internalCateringDescription = event;
    }, 500)
  }
};
</script>

<style scoped>

.notes-field >>> textarea {
  overflow: auto;
  max-height: 15em;
  margin-top: 0 !important;
  padding-top: 10px;
  padding-bottom: 10px;
}

.grid {
  display: grid;
  grid-gap: 8px 4px;
  grid-template-columns: 1fr 25px;
  grid-auto-rows: auto 1fr;
  font-size: 16px;
}

</style>
