<template>
  <span :class="{singleDay}">
    <template v-if="showLeadingDay">
      <span class="day start">{{ startDayString }}</span>
      <span class="separator">{{ dayTimeSeparator }}</span>
    </template>
    <time class="time start" :datetime="startDateTimeString">{{ startTimeString }}</time>
    &ndash;
    <template v-if="showSecondDay">
      <span class="day end">{{ endDayString }}</span>
      <span class="separator">{{ dayTimeSeparator }}</span>
    </template>
    <time class="time end" :datetime="endDateTimeString">{{ endTimeString }}</time>
  </span>
</template>

<script>
import {toDate} from '@/util/dates';

export default {
  name: 'EventTime',
  props: {
    start: {
      type: [Number, String, Object],
      default: new Date()
    },
    end: {
      type: [Number, String, Object],
      default: new Date()
    },
    short: Boolean
  },
  computed: {
    startDate() {
      return toDate(this.start);
    },
    endDate() {
      return toDate(this.end);
    },
    startDay() {
      return this.toDay(this.startDate);
    },
    endDay() {
      return this.toDay(this.endDate);
    },
    singleDay() {
      return this.startDay.getTime() === this.endDay.getTime();
    },
    showLeadingDay() {
      return !this.short;
    },
    showSecondDay() {
      return !this.singleDay && !this.short;
    },
    // does the start and end time cross any meridian boundaries? AM/PM/Day
    singleMeridian() {
      return this.singleDay &&
          Math.trunc(this.startDate.getHours() / 12) === Math.trunc(this.endDate.getHours() / 12);
    },
    dayTimeSeparator() {
      return this.singleDay ? ' · ' : ', ';
    },
    startDayString() {
      const options = {};
      if (!this.singleDay) {
        options.weekday = undefined;
        options.year = 'numeric';
      }
      return this.formatDay(this.startDay, options);
    },
    endDayString() {
      const options = {};
      if (!this.singleDay) {
        options.weekday = undefined;
        options.year = 'numeric';
      }
      return this.formatDay(this.endDay, options);
    },
    startTimeString() {
      return this.formatTime(this.startDate, !this.singleMeridian);
    },
    startDateTimeString() {
      return this.startDate.toISOString();
    },
    endTimeString() {
      return this.formatTime(this.endDate, true);
    },
    endDateTimeString() {
      return this.endDate.toISOString();
    }
  },
  methods: {
    toDay(date) {
      const clone = new Date(date.getTime());
      clone.setHours(0, 0, 0, 0);
      return clone;
    },
    formatDay(date, options = {}) {
      return date.toLocaleDateString(undefined, Object.assign({
        weekday: 'long',
        month: 'long',
        day: 'numeric'
      }, options));
    },
    formatTime(date, meridian = true) {
      let res = date.toLocaleTimeString(undefined, {timeStyle: 'short'});
      if (!meridian) {
        // remove am/pm as there's no way to format a 12-hour date without it
        res = res.replace(/ ?(am|pm)$/i, '');
      }
      if ((date.getHours() === 0 || date.getHours() === 12) && res.startsWith('0') && !res.startsWith('00')) {
        // for some reason 12 hour time results in 0:12 pm instead of 12:12 pm
        res = '12' + res.substr(1);
      }
      return res;
    },
    formatIso(date) {
      return date.toIsoString();
    }
  }
};
</script>

<style scoped>

</style>
