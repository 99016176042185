var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"period-input"},[_c('v-menu',{attrs:{"offset-y":"","nudge-top":"-1","close-on-content-click":false,"content-class":"rounded-t-0"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"ma-0 pa-0",attrs:{"hide-details":"","readonly":"","value":_vm.formattedStartDate}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startDateChooserVisible),callback:function ($$v) {_vm.startDateChooserVisible=$$v},expression:"startDateChooserVisible"}},[_c('v-date-picker',{staticClass:"rounded-t-0",attrs:{"no-title":"","first-day-of-week":_vm.localeFirstDayOfWeek},on:{"input":function($event){_vm.startDateChooserVisible = false}},model:{value:(_vm.startYearMonthDay),callback:function ($$v) {_vm.startYearMonthDay=$$v},expression:"startYearMonthDay"}})],1),_c('v-combobox',{ref:"startTime",staticClass:"ma-0 pa-0",attrs:{"items":_vm.availableStartTimes,"no-filter":"","value-comparator":_vm.compareValues,"hide-details":"","menu-props":{minWidth: 150}},model:{value:(_vm.startTime),callback:function ($$v) {_vm.startTime=$$v},expression:"startTime"}}),_c('v-menu',{attrs:{"offset-y":"","nudge-top":"-1","close-on-content-click":false,"content-class":"rounded-t-0"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"ma-0 pa-0",attrs:{"hide-details":"","readonly":"","value":_vm.formattedEndDate}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endDateChooserVisible),callback:function ($$v) {_vm.endDateChooserVisible=$$v},expression:"endDateChooserVisible"}},[_c('v-date-picker',{staticClass:"rounded-t-0",attrs:{"no-title":"","first-day-of-week":_vm.localeFirstDayOfWeek},on:{"input":function($event){_vm.endDateChooserVisible = false}},model:{value:(_vm.endYearMonthDay),callback:function ($$v) {_vm.endYearMonthDay=$$v},expression:"endYearMonthDay"}})],1),_c('v-combobox',{ref:"endTime",staticClass:"ma-0 pa-0",attrs:{"items":_vm.availableEndTimes,"no-filter":"","value-comparator":_vm.compareValues,"hide-details":"","menu-props":{minWidth: 150}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticStyle:{"min-width":"10em"}},'v-list-item',attrs,false),on),[_c('v-list-item-title',[_vm._v(_vm._s(item.text))]),_c('v-list-item-action-text',{staticClass:"text-no-wrap pr-2"},[_vm._v("("+_vm._s(item.dur)+")")])],1)]}}]),model:{value:(_vm.endTime),callback:function ($$v) {_vm.endTime=$$v},expression:"endTime"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }