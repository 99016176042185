export default {
  methods: {
    eventColor(event) {
      if (event.blocked) return 'striped';
      if (event.rejected) return 'error striped';
      if (!event.accepted) return 'secondary striped';
      let color = 'primary';
      if (event.needsApproval && !event.approved) color = 'secondary';
      if (event.active) color = 'accent';
      if (event.connected) color += ' connected';
      if (event.checkedOut) color += ' checked-out';
      if (event.hasAdjacentBefore) color += ' adjacent-before';
      if (event.hasAdjacentAfter) color += ' adjacent-after';
      return color;
    },
    eventColorIsDark(event) {
      return !event.rejected && event.accepted;
    }
  }
};
